import React from 'react';
import { ExplodedView } from "exploded-view-package";

function App() {
   

    return <ExplodedView></ExplodedView>
}

export default App
